import React from 'react'

const WebsitesIndex = React.lazy(() => import('./views/websites/Index'))
const EmailsIndex = React.lazy(() => import('./views/emails/Index'))
const DomainsIndex = React.lazy(() => import('./views/domains/Index'))
const OrdersIndex = React.lazy(() => import('./views/orders/Index'))
const TemplatesIndex = React.lazy(() => import('./views/templates/Index'))
const ServicesIndex = React.lazy(() => import('./views/services/Index'))
const PlansIndex = React.lazy(() => import('./views/plans/Index'))

const DomainPricesIndex = React.lazy(() => import('./views/domain-prices/Index'))

const routes = [
  { path: '/', exact: true, name: 'Home' },
  { path: '/websites', name: 'Strony', element: WebsitesIndex },
  { path: '/emails', name: 'Konta email', element: EmailsIndex },
  { path: '/domains', name: 'Domeny', element: DomainsIndex },
  { path: '/orders', name: 'Zamówienia', element: OrdersIndex },
  { path: '/templates', name: 'Szablony', element: TemplatesIndex },
  { path: '/services', name: 'Usługi', element: ServicesIndex },
  { path: '/plans', name: 'Pakiety', element: PlansIndex },

  { path: '/domain-prices', name: 'Cenniki domen', element: DomainPricesIndex },

]

export default routes

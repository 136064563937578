import { CCard, CCardBody, CCardTitle, CCol, CRow } from "@coreui/react";
import { useServicesQuery } from "src/services/ServiceService";
import { Order } from "src/types/Order";
import { DefaultListParams } from "src/types/api/ListParams";
import Service from "./Service";

interface ServicesProps {
  cart: Order;
}
const Services = ({ cart }: ServicesProps) => {
  const { currentData: servicesResponse } = useServicesQuery({
    ...DefaultListParams,
    per_page: 10000,
  });
  const services = servicesResponse?.data;
  return (
    <CCard className="mt-4">
      <CCardBody>
        <h5>Usługi dodatkowe</h5>
        <CRow>
          {services?.map((service) => (
            <CCol md={4} key={service.id}>
              <Service service={service} cart={cart} />
            </CCol>
          ))}
        </CRow>
      </CCardBody>
    </CCard>
  );
};

export default Services;

import {
  CCol,
  CFormLabel,
  CFormSelect,
  CImage,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import { skipToken } from "@reduxjs/toolkit/query";
import { subscribe } from "diagnostics_channel";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ApiErrorSetter } from "src/helpers/ApiErrorSetter";
import {
  useAttachDomainMutation,
  useGetDomainQuery,
} from "src/services/DomainService";
import { useGetOrderQuery } from "src/services/OrderService";
import {
  useGetWebsiteQuery,
  useWebsitesQuery,
} from "src/services/WebsiteService";
import { OrderItem } from "src/types/OrderItem";
import { Website } from "src/types/Website";
import { AttachDomainRequest } from "src/types/api/AttachDomainRequest";
import { DefaultListParams } from "src/types/api/ListParams";

const Attach = () => {
  const { id } = useParams();
  const { currentData: domainResponse } = useGetDomainQuery(id ?? skipToken);
  const { currentData: websitesResponse } = useWebsitesQuery({
    ...DefaultListParams,
    per_page: 10000,
  });
  const websites = websitesResponse?.data;
  const domain = domainResponse?.data;

  const methods = useForm<AttachDomainRequest>();
  const {
    handleSubmit,
    reset,
    register,
    setError,
    clearErrors,
    watch,
    formState: { errors },
  } = methods;
  const form = watch();
  const [attachDomain] = useAttachDomainMutation();

  const navigate = useNavigate();
  const handleOnClose = () => {
    navigate("/domains");
  };

  const handleOnSubmit = async () => {
    try {
      if (id) {
        const res = await attachDomain({
          domainId: id,
          request: form,
        }).unwrap();
        handleOnClose();
      }
    } catch (e) {
      ApiErrorSetter(e, setError);
    }
  };

  return (
    <CModal visible={true} size="xl" onClose={handleOnClose}>
      {domain && websitesResponse && (
        <>
          <CModalHeader>
            <h5>Podłącz domenę {domain.name} do strony</h5>
          </CModalHeader>
          <CModalBody>
            <form onSubmit={handleSubmit(handleOnSubmit)}>
              <CFormLabel>Strona</CFormLabel>
              <CFormSelect
                {...register("website_id")}
                invalid={!!errors.website_id}
                feedbackInvalid={errors.website_id?.message}
              >
                {websites?.map((website) => (
                  <option value={website.id} key={website.id}>
                    {website.domain?.name ?? "ID: " + website.id}
                  </option>
                ))}
              </CFormSelect>
            </form>
          </CModalBody>
          <CModalFooter className="mt-5">
            <button onClick={handleOnSubmit} className="btn btn-primary">
              Zapisz
            </button>
            <button
              onClick={handleOnClose}
              className="btn btn-danger text-white"
            >
              Anuluj
            </button>
          </CModalFooter>
        </>
      )}
    </CModal>
  );
};

export default Attach;

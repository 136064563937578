import { useEffect } from "react";
import { useMeQuery, useOvhLoginMutation } from "src/services/AuthService";

const Ovh = () => {
  const [ovhLogin] = useOvhLoginMutation();
  useEffect(() => {
    ovhLogin(`${process.env.REACT_APP_APP_URL}`)
      .unwrap()
      .then((res) => {
        document.location.href = res.redirect;
      });
  }, []);

  return <>OVH</>;
};

export default Ovh;

import { cilTrash } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import {
  CCard,
  CCardBody,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import { useSelector } from "react-redux";
import {
  useGetCartQuery,
  useRemoveCartItemMutation,
} from "src/services/CartService";
import { OrderItem } from "src/types/OrderItem";
import { Subjectable } from "src/types/Subjectable";
import AddDomain from "./AddDomain";
import { Link } from "react-router-dom";
import Services from "./Services";
import { skipToken } from "@reduxjs/toolkit/query";
import { Website } from "src/types/Website";
import { useContext, useEffect } from "react";
import CartContext from "src/context/cart.context";

export const Items = () => {
  const cartId = useSelector<any>((store) => store.cart) as string;
  const { currentData: cartResponse } = useGetCartQuery(cartId ?? skipToken);
  const [removeCartItemMutation] = useRemoveCartItemMutation();
  const cart = cartResponse?.data;
  const websiteConfig = cart?.subjectables.find(
    (s) => s.subjectable_type == "App\\Models\\WebsiteConfig"
  );
  const { link, setLink } = useContext(CartContext);

  useEffect(() => {
    setLink("/cart/checkout");
  }, []);

  const getSubjectable = (item: OrderItem) => {
    switch (item.subjectable_type) {
      case "App\\Models\\Website":
        return <>Strona {(item.subjectable as Website).domain?.name}</>;
      case "App\\Models\\WebsiteConfig":
        return <>Nowa strona</>;
      default:
        return <>-</>;
    }
  };

  const removeCartItem = (itemId: string) => {
    removeCartItemMutation({ cartId: cart?.id, itemId: itemId });
  };

  return (
    <div>
      {cart && (
        <>
          <CCard className="mt-4">
            <CCardBody>
              <h5>Pozycje zamówienia</h5>
              <CTable>
                <CTableHead>
                  <CTableRow>
                    <CTableHeaderCell>Lp.</CTableHeaderCell>
                    <CTableHeaderCell>Nazwa</CTableHeaderCell>
                    <CTableHeaderCell>Dotyczy</CTableHeaderCell>
                    <CTableHeaderCell>Cena</CTableHeaderCell>
                    <CTableHeaderCell></CTableHeaderCell>
                  </CTableRow>
                </CTableHead>
                <CTableBody>
                  {cart?.items.map((item, index) => (
                    <CTableRow key={item.id}>
                      <CTableDataCell>{index + 1}</CTableDataCell>
                      <CTableDataCell>{item.name}</CTableDataCell>
                      <CTableDataCell>{getSubjectable(item)}</CTableDataCell>
                      <CTableDataCell>{item.price}</CTableDataCell>
                      <CTableDataCell>
                        <button
                          className="btn btn-danger text-white"
                          onClick={() => removeCartItem(item.id)}
                        >
                          <CIcon icon={cilTrash} />
                        </button>
                      </CTableDataCell>
                    </CTableRow>
                  ))}
                </CTableBody>
              </CTable>
            </CCardBody>
          </CCard>

          <div className="mt-4">
            {websiteConfig && <AddDomain cart={cart} />}

            {cart.subjectables.length && <Services cart={cart} />}
          </div>
        </>
      )}
    </div>
  );
};

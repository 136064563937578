import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import queryString from "query-string";
import { RootState } from "../store/store";

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL,
  headers: {
    Accept: "application/json",
  },
  prepareHeaders: async (headers, { getState }) => {
    const state = getState() as RootState;
    const token = state.token;

    if (token) headers.set("Authorization", `Bearer ${token}`);
    return headers;
  },
  paramsSerializer: (params) =>
    queryString.stringify(params, {
      arrayFormat: "index",
    }),
});

export default createApi({
  tagTypes: [
    "ME",
    "TEMPLATES",
    "SERVICES",
    "WEBSITES",
    "DOMAINS",
    "DOMAINS_FOR_EMAIL",
    "EMAILS",
    "ORDERS",
    "PLANS",
    "DOMAIN_PRICES",
    "CART",
    "WEBSITE_CONFIG",
  ],
  baseQuery: baseQuery,
  endpoints: () => ({}),
});

import { CButtonGroup, CCol, CContainer, CRow } from "@coreui/react";
import { useState } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import DataTable from "src/components/DataTable/DataTable";
import { useDeletePlanMutation, usePlansQuery } from "src/services/PlanService";
import { DefaultMeta } from "src/types/api/ListMeta";
import { DefaultListParams, ListParams } from "src/types/api/ListParams";
import Swal from "sweetalert2";

const Index = () => {
  const [params, setParams] = useState<ListParams>(DefaultListParams);
  const [deletePlan] = useDeletePlanMutation();
  const { currentData: servicesResponse, isFetching } = usePlansQuery(params);
  const rows = servicesResponse?.data ?? [];
  const meta = servicesResponse?.meta ?? DefaultMeta;
  const handleOnDelete = (id: string) => {
    Swal.fire({
      title: "Czy jesteś pewien?",
      confirmButtonText: "Tak",
      cancelButtonText: "Nie",
      showCancelButton: true,
      showConfirmButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        deletePlan(id);
      }
    });
  };

  const columns = [
    { field: "name", headerName: "Nazwa", flex: 1 },
    {
      field: "website_params.storage_quota",
      headerName: "Przestrzeń dyskowa",
      flex: 1,
      valueGetter: ({ row }: any) => row.website_params.storage_quota,
    },
    {
      field: "website_params.mail_quota",
      headerName: "Przestrzeń na maile",
      flex: 1,
      valueGetter: ({ row }: any) => row.website_params.mail_quota,
    },
    {
      field: "website_params.pages_count",
      headerName: "Ilość podstron",
      flex: 1,
      valueGetter: ({ row }: any) => row.website_params.pages_count,
    },
    {
      field: "price_month",
      headerName: "Cena miesięczna",
      flex: 1,
    },
    {
      field: "price_year",
      headerName: "Cena roczna",
      flex: 1,
    },
    {
      field: "actions",
      headerName: "Akcje",
      flex: 1,
      renderCell: ({ row: { id, status } }: any) => {
        return (
          <CButtonGroup role="group" size="sm">
            <Link
              to={`/plans/${id}/edit`}
              className="btn btn-primary"
              color="primary"
            >
              Edytuj
            </Link>
            <button
              className="btn btn-danger text-white"
              onClick={() => handleOnDelete(id)}
            >
              Usuń
            </button>
          </CButtonGroup>
        );
      },
    },
  ];

  const onParamsChange = (params: any) => {
    setParams({ ...params });
  };

  return (
    <>
      <CRow>
        <CCol className="text-end mb-3">
          <Link to="/plans/create" className="btn btn-primary">
            Dodaj nowy pakiet
          </Link>
        </CCol>
      </CRow>
      <CRow>
        <CCol>
          <DataTable
            onParamsChange={onParamsChange}
            params={{ ...params, loading: isFetching }}
            rows={rows}
            meta={meta}
            columns={columns}
          />
          <Outlet />
        </CCol>
      </CRow>
    </>
  );
};

export default Index;

import React from "react";

import { DataGrid, GridColumns } from "@mui/x-data-grid";
import { ListMeta } from "src/types/api/ListMeta";
import { ListParams } from "src/types/api/ListParams";
import "./DataTable.scss";

export interface DataTableProps<T = any> {
  onParamsChange: (params: ListParams) => void;
  columns: GridColumns<any>;
  rows: T[];
  meta: ListMeta;
  params: ListParams;
}

export const TableBadge = ({ value }: any) => {
  return value ? (
    <span className="badge text-bg-success text-white">Tak</span>
  ) : (
    <span className="badge text-bg-danger text-white">Nie</span>
  );
};

const DataTable = ({
  onParamsChange,
  columns,
  rows,
  meta,
  params,
}: DataTableProps): JSX.Element => {
  const onPageChange = (e: number) => {
    onParamsChange({ ...params, page: e + 1 });
  };

  const onPageSizeChange = (e: number) => {
    onParamsChange({ ...params, page: 1, per_page: e });
  };

  const onSortModelChange = (e: any) => {
    onParamsChange({
      ...params,
      page: 1,
      sort: e[0].field,
      direction: e[0].sort,
    });
  };

  return (
    <div className="Table" data-testid="Table">
      <DataGrid
        disableSelectionOnClick={true}
        rows={rows}
        columns={columns}
        pageSize={params.per_page}
        rowCount={meta?.total ?? 10}
        onPageChange={onPageChange}
        paginationMode="server"
        sortingMode="server"
        rowsPerPageOptions={[15, 20, 50]}
        loading={params.loading}
        pagination
        onPageSizeChange={onPageSizeChange}
        onSortModelChange={onSortModelChange}
      />
    </div>
  );
};

export default DataTable;

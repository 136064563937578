import {
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import { skipToken } from "@reduxjs/toolkit/query";
import { subscribe } from "diagnostics_channel";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useGetOrderQuery } from "src/services/OrderService";
import { OrderItem } from "src/types/OrderItem";
import { Website } from "src/types/Website";
import CreateDomain, { CheckDomain } from "../cart/domains/CreateDomain";
import { useCreateDomainMutation } from "src/services/DomainService";
import { useState } from "react";

const Create = () => {
  const navigate = useNavigate();
  const [domainName, setDomainName] = useState<string>();
  const [createDomain] = useCreateDomainMutation();
  const handleOnClose = () => {
    navigate("/domains");
  };

  const handleOnChange = (domain: string) => {
    setDomainName(domain);
  };
  const handleOnSubmit = async () => {
    try {
      if (domainName) {
        const res = await createDomain({
          name: domainName,
        }).unwrap();
        handleOnClose();
      }
    } catch (e) {}
  };
  return (
    <CModal visible={true} size="xl" onClose={handleOnClose}>
      <CModalHeader>
        <h5>Dodaj własną domenę</h5>
      </CModalHeader>
      <CModalBody>
        <CreateDomain
          showSubmitButton={false}
          onSubmit={handleOnSubmit}
          onChange={handleOnChange}
        />
      </CModalBody>
      <CModalFooter className="mt-5">
        <button onClick={handleOnSubmit} className="btn btn-primary">
          Zapisz
        </button>
        <button onClick={handleOnClose} className="btn btn-danger text-white">
          Anuluj
        </button>
      </CModalFooter>
    </CModal>
  );
};

export default Create;

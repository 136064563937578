import { FetchBaseQueryError } from "@reduxjs/toolkit/query";

export const ApiErrorSetter = (e: any, setError: any) => {
  const error = e as FetchBaseQueryError;

  Object.entries<Record<any, any>>((error.data as any).errors).forEach(
    ([item, errors]) => {
      return setError(item as any, {
        message: errors.join(","),
      });
    }
  );
};

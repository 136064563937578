import {
  CButtonGroup,
  CCard,
  CCardBody,
  CCol,
  CContainer,
  CRow,
} from "@coreui/react";
import { useState } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import DataTable, { TableBadge } from "src/components/DataTable/DataTable";
import {
  useDomainPricesQuery,
  useDeleteDomainPriceMutation,
} from "src/services/DomainPriceService";
import { DefaultMeta } from "src/types/api/ListMeta";
import { DefaultListParams, ListParams } from "src/types/api/ListParams";
import Swal from "sweetalert2";

const Index = () => {
  const [params, setParams] = useState<ListParams>(DefaultListParams);
  const [deleteDomainPrice] = useDeleteDomainPriceMutation();

  const handleOnDelete = (id: string) => {
    Swal.fire({
      title: "Czy jesteś pewien?",
      confirmButtonText: "Tak",
      cancelButtonText: "Nie",
      showCancelButton: true,
      showConfirmButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        deleteDomainPrice(id);
      }
    });
  };

  const { currentData: servicesResponse, isFetching } =
    useDomainPricesQuery(params);
  const rows = servicesResponse?.data ?? [];
  const meta = servicesResponse?.meta ?? DefaultMeta;

  const navigate = useNavigate();

  const columns = [
    { field: "extension", headerName: "Rozszerzenie", flex: 1 },
    { field: "register_price", headerName: "Cena rejestracji", flex: 1 },
    { field: "renewal_price", headerName: "Cena odnowienia", flex: 1 },
    { field: "transfer_price", headerName: "Cena transferu", flex: 1 },
    {
      field: "active",
      headerName: "Aktywny",
      flex: 1,
      renderCell: (params: any) => <TableBadge value={params.value} />,
    },
    {
      field: "actions",
      headerName: "Akcje",
      flex: 1,
      renderCell: ({ row: { id, status } }: any) => {
        return (
          <CButtonGroup role="group" size="sm">
            <Link
              to={`/domain-prices/${id}/edit`}
              className="btn btn-primary"
              color="primary"
            >
              Edytuj
            </Link>
            <button
              className="btn btn-danger text-white"
              onClick={() => handleOnDelete(id)}
            >
              Usuń
            </button>
          </CButtonGroup>
        );
      },
    },
  ];

  const onParamsChange = (params: any) => {
    setParams({ ...params });
  };

  return (
    <>
      <CRow>
        <CCol className="text-end mb-3">
          <CButtonGroup>
            <Link to="/domain-prices/create" className="btn btn-primary">
              Dodaj cennik domeny
            </Link>
          </CButtonGroup>
        </CCol>
      </CRow>

      <CRow>
        <CCol>
          <DataTable
            onParamsChange={onParamsChange}
            params={{ ...params, loading: isFetching }}
            rows={rows}
            meta={meta}
            columns={columns}
          />
          <Outlet />
        </CCol>
      </CRow>
    </>
  );
};

export default Index;

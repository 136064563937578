import { cilLockLocked, cilUser } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import {
  CButton,
  CCol,
  CForm,
  CFormFeedback,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
} from "@coreui/react";
import { useEffect } from "react";
import { useForm, useFormContext } from "react-hook-form";
import { LoginRequest } from "src/types/api/LoginRequest";
interface LoginFormProps {
  onSubmit: any;
}

export const Form = ({ onSubmit }: LoginFormProps) => {
  const { register, handleSubmit } = useForm<LoginRequest>();

  const {
    formState: { errors },
  } = useFormContext<LoginRequest>();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h1>Logowanie</h1>
      <p className="text-body-secondary">Witaj w sitely.pl!</p>
      <CInputGroup className="mb-3">
        <span className="input-group-text">
          <CIcon icon={cilUser} />
        </span>
        <CFormInput
          {...register("email")}
          invalid={!!errors.email}
          feedbackInvalid={errors.email?.message}
        />
      </CInputGroup>
      <CInputGroup className="mb-4">
        <span className="input-group-text">
          <CIcon icon={cilLockLocked} />
        </span>
        <CFormInput
          invalid={!!errors.password}
          type="password"
          {...register("password")}
          feedbackInvalid={errors.password?.message}
        />
      </CInputGroup>
      <CRow>
        <CCol xs={6}>
          <button className="btn btn-primary px-4" type="submit">
            Zaloguj
          </button>
        </CCol>
        <CCol xs={6} className="text-right">
          <button color="link" className="btn btn-link px-0">
            Nie pamiętasz hasła?
          </button>
        </CCol>
      </CRow>
    </form>
  );
};

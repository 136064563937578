import { createSlice, current } from "@reduxjs/toolkit";

export interface Subjectable {
  id: string;
  type: string;
}

const initialState: Subjectable = {
  id: "",
  type: "",
};

export const CartReducer = createSlice({
  name: "cart",
  initialState: initialState,
  reducers: {
    setCartId: (state, action) => action.payload,
  },
});

export const { setCartId } = CartReducer.actions;

import {
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import { skipToken } from "@reduxjs/toolkit/query";
import { subscribe } from "diagnostics_channel";
import { FormProvider, useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useGetOrderQuery } from "src/services/OrderService";
import { useGetPlanQuery } from "src/services/PlanService";
import {
  useGetTemplateQuery,
  useUpdateTemplateMutation,
} from "src/services/TemplateService";
import { OrderItem } from "src/types/OrderItem";
import { Template } from "src/types/Template";
import { Website } from "src/types/Website";
import Form from "./Form";
import { useEffect } from "react";
import { ApiErrorSetter } from "src/helpers/ApiErrorSetter";

const Update = () => {
  const { id } = useParams();
  const { currentData: templateResponse } = useGetTemplateQuery(
    id ?? skipToken
  );
  const template = templateResponse?.data;

  const methods = useForm<Template>();
  const { reset, watch, setError, clearErrors } = methods;
  const [updateTemplate] = useUpdateTemplateMutation();

  const navigate = useNavigate();
  const handleOnClose = () => {
    navigate("/templates");
  };

  useEffect(() => {
    reset(template);
  }, [template]);

  const form = watch();

  const handleOnSubmit = async () => {
    try {
      clearErrors();
      const res = await updateTemplate(form).unwrap();
      handleOnClose();
    } catch (e) {
      ApiErrorSetter(e, setError);
    }
  };

  return (
    <CModal visible={true} size="xl" onClose={handleOnClose}>
      {template && (
        <>
          <CModalHeader>
            <h5>Szablon {template.name}</h5>
          </CModalHeader>
          <CModalBody>
            <FormProvider {...methods}>
              <Form onSubmit={handleOnSubmit} />
            </FormProvider>
          </CModalBody>
          <CModalFooter className="mt-5">
            <button onClick={handleOnSubmit} className="btn btn-primary">
              Zapis
            </button>
            <button
              onClick={handleOnClose}
              className="btn btn-danger text-white"
            >
              Zamknij
            </button>
          </CModalFooter>
        </>
      )}
    </CModal>
  );
};

export default Update;

import {
  CCard,
  CCardBody,
  CFormInput,
  CInputGroup,
  CTab,
  CTabContent,
  CTabList,
  CTabPanel,
  CTabs,
} from "@coreui/react";
import CreateSubdomain from "./domains/CreateSubdomain";
import CreateDomain, { CheckDomain } from "./domains/CreateDomain";
import RegisterDomain from "./domains/RegisterDomain";
import { Order } from "src/types/Order";
import { useAddDomainToCartMutation } from "src/services/CartService";

interface AddDomainProps {
  cart: Order;
}

const AddDomain = ({ cart }: AddDomainProps) => {
  const hasDomain = cart.items.find(
    (item) =>
      item.orderable_type == "App\\Models\\DomainAction" &&
      item.subjectable_type == "App\\Models\\WebsiteConfig"
  );
  const subjectable = cart.subjectables.find(
    (s) => s.subjectable_type == "App\\Models\\WebsiteConfig"
  );

  const [addDomainToCart] = useAddDomainToCartMutation();

  const handleOnSubmit = async (form: CheckDomain) => {
    try {
      await addDomainToCart({
        cartId: cart.id,
        domainAction: {
          name: form.name,
          action: 0,
          ...subjectable,
        },
      }).unwrap();
    } catch (e) {}
  };

  return !hasDomain ? (
    <CCard>
      <CCardBody>
        <h5>Wybierz adres dla nowej strony</h5>

        <CTabs activeItemKey="create_subdomain">
          <CTabList variant="tabs">
            <CTab itemKey="create_subdomain">Subdomena</CTab>
            <CTab itemKey="create_domain">Własna domena</CTab>
            <CTab itemKey="order_domain">Zamów domenę</CTab>
          </CTabList>
          <CTabContent>
            <CTabPanel className="p-3" itemKey="create_subdomain">
              <CreateSubdomain onSubmit={handleOnSubmit} />
            </CTabPanel>
            <CTabPanel className="p-3" itemKey="create_domain">
              <CreateDomain onSubmit={handleOnSubmit} />
            </CTabPanel>
            <CTabPanel className="p-3" itemKey="order_domain">
              <RegisterDomain cart={cart} subjectable={subjectable} />
            </CTabPanel>
          </CTabContent>
        </CTabs>
      </CCardBody>
    </CCard>
  ) : (
    <></>
  );
};

export default AddDomain;

import { skipToken } from "@reduxjs/toolkit/query";
import { useSelector } from "react-redux";
import { useGetCartQuery } from "src/services/CartService";
import { useGetWebsiteQuery } from "src/services/WebsiteService";
import { Subjectable as SubjectableType } from "src/store/reducers/cart";
import { Order } from "src/types/Order";

export interface SubjectableProps {
  cart: Order;
}
const Subjectable = ({ cart }: SubjectableProps) => {
  const subjectables = cart.subjectables;
  return (
    <>
      {subjectables.map((subjectable) => {
        if (subjectable.subjectable_type === "App\\Models\\Website")
          return <p key={subjectable.subjectable_id}>Istniejąca strona</p>;
        if (subjectable.subjectable_type === "App\\Models\\WebsiteConfig")
          return <p key={subjectable.subjectable_id}>Nowa strona</p>;
      })}
    </>
  );
};

export default Subjectable;

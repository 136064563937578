import {
  CCol,
  CImage,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import { skipToken } from "@reduxjs/toolkit/query";
import { subscribe } from "diagnostics_channel";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useGetOrderQuery } from "src/services/OrderService";
import { useGetWebsiteQuery } from "src/services/WebsiteService";
import { OrderItem } from "src/types/OrderItem";
import { Website } from "src/types/Website";

const Show = () => {
  const { id } = useParams();
  const { currentData: websiteResponse } = useGetWebsiteQuery(id ?? skipToken);
  const website = websiteResponse?.data;

  const navigate = useNavigate();
  const handleOnClose = () => {
    navigate("/websites");
  };
  return (
    <CModal visible={true} size="xl" onClose={handleOnClose}>
      {website && (
        <>
          <CModalHeader>
            <h5>Szczegóły strony {website?.domain?.name}</h5>
          </CModalHeader>
          <CModalBody>
            <CRow>
              <CCol>
                <CImage rounded fluid src={website.screenshot} />
              </CCol>
              <CCol>
                <CTable>
                  <CTableBody>
                    <CTableRow>
                      <CTableDataCell>Domena:</CTableDataCell>
                      <CTableHeaderCell>
                        {website.domain?.name ?? "-"}
                      </CTableHeaderCell>
                    </CTableRow>
                    <CTableRow>
                      <CTableDataCell>Pakiet:</CTableDataCell>
                      <CTableHeaderCell>
                        {website.plan?.name ?? "-"}
                      </CTableHeaderCell>
                    </CTableRow>
                    <CTableRow>
                      <CTableDataCell>Szablon:</CTableDataCell>
                      <CTableHeaderCell>
                        {website.template?.name ?? "-"}
                      </CTableHeaderCell>
                    </CTableRow>
                    <CTableRow>
                      <CTableDataCell>Przestrzeń dyskowa:</CTableDataCell>
                      <CTableHeaderCell>
                        {website.storage_usage} /{" "}
                        {website.website_params?.storage_quota}
                      </CTableHeaderCell>
                    </CTableRow>
                    <CTableRow>
                      <CTableDataCell>Przestrzeń na maile:</CTableDataCell>
                      <CTableHeaderCell>
                        {website.mail_usage} /{" "}
                        {website.website_params?.mail_quota}
                      </CTableHeaderCell>
                    </CTableRow>

                    <CTableRow>
                      <CTableDataCell>Ilość stron:</CTableDataCell>
                      <CTableHeaderCell>
                        {website.website_params?.pages_count}
                      </CTableHeaderCell>
                    </CTableRow>
                    {website.services.length && (
                      <>
                        <CTableRow>
                          <CTableDataCell colSpan={2}>Usługi:</CTableDataCell>
                        </CTableRow>
                        {website.services.map((service) => (
                          <CTableRow key={service.id}>
                            <CTableDataCell>{service.name}</CTableDataCell>
                          </CTableRow>
                        ))}
                      </>
                    )}
                  </CTableBody>
                </CTable>
              </CCol>
            </CRow>
          </CModalBody>
          <CModalFooter className="mt-5">
            <button onClick={handleOnClose} className="btn btn-primary">
              Zamknij
            </button>
          </CModalFooter>
        </>
      )}
    </CModal>
  );
};

export default Show;

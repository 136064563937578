import React, { useEffect, useState } from "react";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilLockLocked, cilUser } from "@coreui/icons";
import { useLoginMutation, useMeQuery } from "src/services/AuthService";
import { FormProvider, useForm } from "react-hook-form";
import { LoginRequest } from "src/types/api/LoginRequest";
import { Form } from "./Form";
import { FetchBaseQueryError, skipToken } from "@reduxjs/toolkit/query";

import { useDispatch, useSelector } from "react-redux";
import path from "path";

const Login = () => {
  const [login] = useLoginMutation();
  const { data: meResponse, isError } = useMeQuery();
  const me = meResponse?.data;

  const navigate = useNavigate();

  const methods = useForm<LoginRequest>();
  const [searchParams] = useSearchParams();

  const redirect = searchParams.get("to") ?? "/";

  const handleOnSubmit = async (form: LoginRequest) => {
    try {
      methods.clearErrors();
      await login(form).unwrap();
    } catch (e) {
      const error = e as FetchBaseQueryError;

      Object.entries<Record<any, any>>((error.data as any).errors).forEach(
        ([item, errors]) => {
          return methods.setError(item as any, {
            message: errors.join(","),
          });
        }
      );
    }
  };

  useEffect(() => {
    if (me) {
      console.log(me);
      navigate(redirect, { replace: true });
    }
  }, [me]);

  return me ? (
    <></>
  ) : (
    <div className="bg-body-tertiary min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={8}>
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <FormProvider {...methods}>
                    <Form onSubmit={handleOnSubmit} />
                  </FormProvider>
                </CCardBody>
              </CCard>
              <CCard
                className="text-white bg-primary py-5"
                style={{ width: "44%" }}
              >
                <CCardBody className="text-center">
                  <div>
                    <h2>Załóż konto</h2>
                    <p>
                      Dołącz do sitely.pl i nie martw się utrzymaniem twojej
                      strony internetowej.
                    </p>
                    <Link
                      to={{
                        pathname: "/register",
                        search: searchParams.toString(),
                      }}
                    >
                      <button
                        color="primary"
                        className="btn btn-primary active mt-3"
                        tabIndex={-1}
                      >
                        Zarejestruj się!
                      </button>
                    </Link>
                  </div>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default Login;

import {
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import { skipToken } from "@reduxjs/toolkit/query";
import { subscribe } from "diagnostics_channel";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useGetOrderQuery } from "src/services/OrderService";
import { OrderItem } from "src/types/OrderItem";
import { Website } from "src/types/Website";
import Form from "./Form";
import { FormProvider, useForm } from "react-hook-form";
import { Email } from "src/types/Email";
import { useCreateEmailMutation } from "src/services/EmailService";
import { ApiErrorSetter } from "src/helpers/ApiErrorSetter";

const Create = () => {
  const methods = useForm<Email>();
  const [createEmail] = useCreateEmailMutation();
  const navigate = useNavigate();
  const handleOnClose = () => {
    navigate("/emails");
  };
  const form = methods.watch();

  const handleOnSubmit = async () => {
    try {
      methods.clearErrors();
      const res = await createEmail(form).unwrap();
      handleOnClose();
    } catch (e) {
      ApiErrorSetter(e, methods.setError);
    }
  };

  return (
    <CModal visible={true} size="xl" onClose={handleOnClose}>
      <CModalHeader>
        <h5>Dodaj adres email</h5>
      </CModalHeader>
      <CModalBody>
        <FormProvider {...methods}>
          <Form onSubmit={handleOnSubmit} />
        </FormProvider>
      </CModalBody>
      <CModalFooter className="mt-5">
        <button onClick={handleOnSubmit} className="btn btn-primary">
          Zapisz
        </button>
        <button onClick={handleOnClose} className="btn btn-danger text-white">
          Anuluj
        </button>
      </CModalFooter>
    </CModal>
  );
};

export default Create;

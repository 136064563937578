import { combineReducers } from "redux";
import { TokenReducer } from "./token";
import Api from "../../services/Api";
import { CartReducer } from "./cart";
import { SidebarReducer } from "./sidebar";

const AppReducer = combineReducers({
  [Api.reducerPath]: Api.reducer,
  [TokenReducer.name]: TokenReducer.reducer,
  [CartReducer.name]: CartReducer.reducer,
  [SidebarReducer.name]: SidebarReducer.reducer,
});

export default AppReducer;

import { skipToken } from "@reduxjs/toolkit/query";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CardForm from "src/components/CardForm";
import {
  useGetOrderQuery,
  usePayOrderByCardMutation,
  usePayOrderMutation,
} from "src/services/OrderService";

const Pay = () => {
  const navigate = useNavigate();
  const { orderId } = useParams();
  const { currentData: orderResponse } = useGetOrderQuery(orderId ?? skipToken);
  const order = orderResponse?.data;
  const [payOrder] = usePayOrderMutation();
  const [payOrderByCard] = usePayOrderByCardMutation();

  const [transaction, setTransaction] = useState<any>();

  const handlePayOrder = async () => {
    if (order) {
      try {
        const res = await payOrder({
          orderId: order.id,
          continueUrl: `${process.env.REACT_APP_APP_URL}/cart/pay/${order.id}/thank-you`,
        }).unwrap();
        document.location.href = res.link;
      } catch (e) {}
    }
  };

  const handlePayCard = async () => {
    if (order) {
      try {
        const res = await payOrderByCard({
          orderId: order.id,
          continueUrl: `${process.env.REACT_APP_APP_URL}/cart/pay/${order.id}/thank-you`,
        }).unwrap();
      } catch (e: any) {
        setTransaction(e.data.transaction);
      }
    }
  };

  const dictionary = {
    cardHolderLabel: "Imię i nazwisko",
    cardNumberLabel: "Numer karty",
    cvvLabel: "Kod CVV",
    expDateLabel: "Data wygaśnięcia karty",
    payButtonCaption: "Zapłać",
    threeDSAuthMessage: "Weryfikacja",
  };

  const callbackSuccess = () => {
    if (order) navigate(`/cart/pay/${order.id}/thank-you`);
  };

  const callbackFail = () => {
    console.log("fail");
  };
  return (
    <>
      <button onClick={handlePayOrder}>Płatność jednorazowa</button>
      <button onClick={handlePayCard}>Płatność kartą</button>
      {transaction && (
        <CardForm
          onFail={callbackFail}
          onSuccess={callbackSuccess}
          token={transaction.token}
          signature={transaction.signature}
        />
      )}
    </>
  );
};

export default Pay;

import { Domain } from "src/types/Domain";
import { ListResponse, SingleItemResponse } from "../types/api/Response";
import Api from "./Api";
import { ListParams } from "src/types/api/ListParams";
import { AttachDomainRequest } from "src/types/api/AttachDomainRequest";

export const DomainService = Api.injectEndpoints({
  endpoints: (build) => ({
    domains: build.query<ListResponse<Domain>, ListParams>({
      query: (params) => ({
        url: `/domains`,
        params: params,
      }),
      providesTags: ["DOMAINS"],
    }),

    getDomain: build.query<SingleItemResponse<Domain>, string>({
      query: (id) => ({
        url: `domains/${id}`,
      }),
      providesTags: (result: any, error: any, arg: any) => [
        { type: "DOMAINS", params: arg },
        "DOMAINS",
      ],
    }),

    attachDomain: build.mutation<
      SingleItemResponse<Domain>,
      { domainId: string; request: AttachDomainRequest }
    >({
      query: ({ domainId, request }) => ({
        url: `/domains/${domainId}/attach`,
        body: request,
        method: "POST",
      }),

      invalidatesTags: ["WEBSITES", "DOMAINS", "EMAILS"],
    }),

    createDomain: build.mutation<SingleItemResponse<Domain>, Domain>({
      query: (domain: Domain) => ({
        url: `/domains`,
        body: domain,
        method: "POST",
      }),

      invalidatesTags: ["DOMAINS"],
    }),

    domainsForEmail: build.query<ListResponse<Domain>, ListParams>({
      query: (params) => ({
        url: `/domains/for-email`,
        params: params,
      }),
      providesTags: ["DOMAINS_FOR_EMAIL"],
    }),

    deleteDomain: build.mutation<null, string>({
      query: (id: string) => ({
        url: `/domains/${id}`,
        method: "DELETE",
      }),

      invalidatesTags: ["DOMAINS", "DOMAINS_FOR_EMAIL"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useDomainsQuery,
  useLazyDomainsQuery,
  useDomainsForEmailQuery,
  useLazyDomainsForEmailQuery,
  useGetDomainQuery,
  useLazyGetDomainQuery,
  useAttachDomainMutation,
  useDeleteDomainMutation,
  useCreateDomainMutation,
} = DomainService;

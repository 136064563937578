const constants = {
  ORDER_STATUS_CART: 0,
  ORDER_STATUS_WAITING_FOR_PAYMENT: 1,
  ORDER_STATUS_PAYMENT_VERIFICATION: 2,
  ORDER_STATUS_FINISHED: 3,
  ORDER_STATUS_CANCELED: 4,

  ORDER_STATUS_CART_TEXT: "Koszyk",
  ORDER_STATUS_WAITING_FOR_PAYMENT_TEXT: "Oczekuje na płatność",
  ORDER_STATUS_PAYMENT_VERIFICATION_TEXT: "Płatność przetwarzana",
  ORDER_STATUS_FINISHED_TEXT: "Zakończone",
  ORDER_STATUS_CANCELED_TEXT: "Anulowane",

  DOMAIN_TYPE_SUBDOMAIN: 0,
  DOMAIN_TYPE_EXTERNAL: 1,
  DOMAIN_TYPE_REGISTERED: 2,

  SERVICE_TYPE_STANDARD: 0,
  SERVICE_TYPE_RECURING: 1,
  SERVICE_TYPE_PER_PAGE: 2,
};

export default constants;

import { skipToken } from "@reduxjs/toolkit/query";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  useAddPlanToCartMutation,
  useCreateWebsiteConfigMutation,
  useGetCartQuery,
} from "src/services/CartService";

import { useGetWebsiteQuery } from "src/services/WebsiteService";
import { Subjectable } from "src/types/Subjectable";

const AddPlanToCart = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [addPlanToCart] = useAddPlanToCartMutation();
  const [createWebsiteConfig] = useCreateWebsiteConfigMutation();

  const cartId = useSelector<any>((store) => store.cart) as string;
  const templateId = searchParams.get("template_id");
  const planId = searchParams.get("plan_id");
  const websiteId = searchParams.get("website_id");
  const interval = searchParams.get("interval");

  const { currentData: cartResponse } = useGetCartQuery(cartId ?? skipToken);

  const { currentData: websiteResponse } = useGetWebsiteQuery(
    websiteId ?? skipToken
  );
  const [subjectable, setSubjectable] = useState<Subjectable>();
  const cart = cartResponse?.data;

  const website = websiteResponse?.data;
  const websiteConfig = cart?.subjectables.find(
    (s) => s.subjectable_type == "App\\Models\\WebsiteConfig"
  );

  useEffect(() => {
    if (cart) {
      if (!websiteId) {
        if (templateId) {
          if (!websiteConfig) {
            createWebsiteConfig({
              cartId: cart.id,
              templateId: templateId,
            })
              .unwrap()
              .then((res) => {
                setSubjectable({
                  subjectable_type: "App\\Models\\WebsiteConfig",
                  subjectable_id: res.data.id,
                  subjectable: res.data,
                });
              })
              .catch(() => {});
          } else {
            setSubjectable(websiteConfig);
          }
        }
      } else {
        if (website) {
          setSubjectable({
            subjectable_id: websiteId,
            subjectable_type: "App\\Models\\Website",
            subjectable: website,
          });
        }
      }
    }
  }, [subjectable, cart, templateId, websiteId, website, websiteConfig]);

  useEffect(() => {
    if (cart) {
      if (subjectable) {
        if (planId && interval) {
          addPlanToCart({
            cartId: cart.id,
            planId: planId,
            interval: interval,
            subjectableId: subjectable.subjectable_id,
            subjectableType: subjectable.subjectable_type,
          });

          navigate("/cart");
        }
      }
    }
  }, [cart, subjectable, planId, interval]);

  return <></>;
};

export default AddPlanToCart;

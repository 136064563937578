import {
  CBadge,
  CCard,
  CCardBody,
  CFormLabel,
  CFormSelect,
  CModal,
  CModalBody,
  CModalFooter,
} from "@coreui/react";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import constants from "src/constants";
import { useAddServiceToCartMutation } from "src/services/CartService";
import { Order } from "src/types/Order";
import { Service as ServiceType } from "src/types/Service";
import { Subjectable } from "src/types/Subjectable";
import { Website } from "src/types/Website";

interface ServiceProps {
  service: ServiceType;
  cart: Order;
}
interface SelectSubjectable {
  index: number;
}
const Service = ({ service, cart }: ServiceProps) => {
  const [addServiceToCart] = useAddServiceToCartMutation();
  const subjectables = cart.subjectables;
  const [showSelectModal, setShowSelectModal] = useState<boolean>();
  const methods = useForm<SelectSubjectable>();
  const { register, handleSubmit, watch } = methods;
  const selectedIndex = watch("index");
  const selectedSubjectable = subjectables[selectedIndex];

  /*
1. odfiltruj subjectable, które mają tę usługę w koszyku,
2. odfiltruj subjectable, jeśli usługa jest cykliczna lub per page i subjectable nie ma website_params,
3. Odfiltruj subjectable, jeśli usługa jest cykliczna i subjectable jest website i website ma juz tę usługę kiedyś wykupioną
  */
  const filteredSubjectables = subjectables
    .filter(
      (s) =>
        !cart.items.find(
          (item) =>
            item.orderable_type == "App\\Models\\Service" &&
            item.orderable_id == service.id &&
            item.subjectable_type == s.subjectable_type &&
            item.subjectable_id == s.subjectable_id
        )
    )
    .filter(
      (s) =>
        !cart.items.find(
          (item) =>
            (service.type == constants.SERVICE_TYPE_RECURING ||
              service.type == constants.SERVICE_TYPE_PER_PAGE) &&
            item.subjectable_id == s.subjectable_id &&
            item.subjectable_type == s.subjectable_type &&
            !item.subjectable.website_params
        )
    )

    .filter(
      (s) =>
        !cart.items.find(
          (item) =>
            service.type == constants.SERVICE_TYPE_RECURING &&
            item.subjectable_id == s.subjectable_id &&
            item.subjectable_type == s.subjectable_type &&
            s.subjectable_type == "App\\Models\\Website" &&
            (item.subjectable as Website).services.find(
              (sv) => sv.id == item.orderable_id
            )
        )
    );

  const disabled = filteredSubjectables.length == 0;

  const handleOnClick = async (subjectable?: Subjectable) => {
    if (filteredSubjectables.length > 1 && !subjectable) {
      setShowSelectModal(true);
    } else {
      const firstSubjectable = filteredSubjectables[0];
      if (firstSubjectable) {
        try {
          const res = addServiceToCart({
            cartId: cart.id,
            serviceId: service.id,
            subjectable: firstSubjectable,
          }).unwrap();
          setShowSelectModal(false);
        } catch (e) {}
      }
    }
  };

  const getSubjectLabel = (subjectable: Subjectable) => {
    if (subjectable.subjectable_type == "App\\Models\\Website") {
      return "Strona " + (subjectable.subjectable as Website).domain?.name;
    }
    if (subjectable.subjectable_type == "App\\Models\\WebsiteConfig") {
      return "Nowa strona";
    }
    return <>-</>;
  };
  return (
    <>
      <CModal visible={showSelectModal}>
        <CModalBody>
          <FormProvider {...methods}>
            <CFormLabel>Wybierz element, którego dotyczy ta usługa</CFormLabel>
            <CFormSelect {...register("index")}>
              {filteredSubjectables.map((subjectable, index) => (
                <option key={subjectable.subjectable_id} value={index}>
                  {getSubjectLabel(subjectable)}
                </option>
              ))}
            </CFormSelect>
          </FormProvider>
        </CModalBody>

        <CModalFooter className="mt-5">
          <button
            className="btn btn-primary"
            onClick={() => handleOnClick(selectedSubjectable)}
          >
            Dodaj
          </button>
          <button
            className="btn btn-danger text-white"
            onClick={() => setShowSelectModal(false)}
          >
            Anuluj
          </button>
        </CModalFooter>
      </CModal>

      <CCard className="mt-4">
        <CCardBody>
          <h5>{service.name}</h5>
          {service.type == constants.SERVICE_TYPE_RECURING && (
            <span className="badge bg-primary" color="primary">
              Cykliczna
            </span>
          )}
          {service.type == constants.SERVICE_TYPE_PER_PAGE && (
            <span className="badge bg-primary" color="primary">
              Za ilość podstron
            </span>
          )}

          {service.type == constants.SERVICE_TYPE_STANDARD && (
            <span className="badge bg-primary" color="primary">
              Standard
            </span>
          )}
          <div className="mt-3">
            {service.type == constants.SERVICE_TYPE_RECURING && (
              <>
                <p>Cena miesięczna: {service.price_month}</p>
                <p>Cena roczna: {service.price_year}</p>
              </>
            )}
            {service.price && <p>Cena: {service.price}</p>}
            {service.price_per_page && (
              <p>Cena za podstronę: {service.price_per_page}</p>
            )}
          </div>
          <div>
            <button
              className="btn btn-primary mt-4"
              onClick={() => handleOnClick()}
              disabled={disabled}
            >
              Dodaj do zamówienia
            </button>
          </div>
        </CCardBody>
      </CCard>
    </>
  );
};

export default Service;

import { cilUser } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import {
  CCard,
  CCardBody,
  CCol,
  CFormCheck,
  CFormInput,
  CInputGroup,
  CRow,
} from "@coreui/react";
import { useContext, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { useMeQuery } from "src/services/AuthService";
import { CheckoutRequest } from "src/types/api/CheckoutRequest";
import Register from "../pages/register/Register";
import { useCheckoutMutation, useGetCartQuery } from "src/services/CartService";
import { useSelector } from "react-redux";
import { FetchBaseQueryError, skipToken } from "@reduxjs/toolkit/query";
import CartContext from "src/context/cart.context";
import { IMaskMixin } from "react-imask";
import MaskedInput from "src/components/MaskedInput";

const Checkout = () => {
  const { currentData: meResponse, isFetching } = useMeQuery();
  const { link, setLink } = useContext(CartContext);
  const navigate = useNavigate();
  const me = meResponse?.data;
  const location = useLocation();
  const methods = useForm<CheckoutRequest>();
  const isCompany = methods.watch("is_company");
  const [checkout] = useCheckoutMutation();
  const cartId = useSelector<any>((store) => store.cart) as string;

  const { currentData: cartResponse } = useGetCartQuery(
    cartId ? cartId : skipToken
  );

  const cart = cartResponse?.data;

  const {
    register,
    reset,
    formState: { errors },
  } = methods;

  useEffect(() => {
    if (!isFetching && !me) {
      navigate({
        pathname: "/login",
        search: createSearchParams({
          to: location.pathname,
        }).toString(),
      });
    }
  }, [me, isFetching]);

  useEffect(() => {
    setLink(null);
  }, [cart]);

  useEffect(() => {
    reset(me);
  }, [me]);

  const handleOnSubmit = async (data: CheckoutRequest) => {
    if (cart) {
      try {
        const res = await checkout({
          cartId: cart.id,
          checkout: data,
        }).unwrap();

        navigate(`/cart/pay/${res.data.id}`);
      } catch (e) {
        const error = e as FetchBaseQueryError;

        Object.entries<Record<any, any>>((error.data as any).errors).forEach(
          ([item, errors]) => {
            return methods.setError(item as any, {
              message: errors.join(","),
            });
          }
        );
      }
    }
  };
  return (
    <>
      <FormProvider {...methods}>
        <CCard className="mt-4">
          <CCardBody>
            {me && (
              <form onSubmit={methods.handleSubmit(handleOnSubmit)}>
                <h5>Wypełnij swoje dane</h5>
                <CRow>
                  <CCol md={6}>
                    <CInputGroup className="mb-3">
                      <CFormInput
                        placeholder="Imię"
                        invalid={!!errors.first_name}
                        feedbackInvalid={errors.first_name?.message}
                        {...methods.register("first_name")}
                      />
                    </CInputGroup>
                    <CInputGroup className="mb-3">
                      <CFormInput
                        placeholder="Nazwisko"
                        invalid={!!errors.last_name}
                        feedbackInvalid={errors.last_name?.message}
                        {...methods.register("last_name")}
                      />
                    </CInputGroup>

                    <CInputGroup className="mb-3">
                      <CFormCheck
                        {...methods.register("is_company")}
                        invalid={!!errors.is_company}
                        feedbackInvalid={errors.is_company?.message}
                        label="Czy firma?"
                      />
                    </CInputGroup>
                  </CCol>

                  {isCompany && (
                    <CCol md={6}>
                      <CInputGroup className="mb-3">
                        <CFormInput
                          placeholder="Nazwa firmy"
                          invalid={!!errors.company_name}
                          feedbackInvalid={errors.company_name?.message}
                          {...methods.register("company_name")}
                        />
                      </CInputGroup>
                      <CInputGroup className="mb-3">
                        <CFormInput
                          placeholder="NIP"
                          invalid={!!errors.tax_id}
                          feedbackInvalid={errors.tax_id?.message}
                          {...methods.register("tax_id")}
                        />
                      </CInputGroup>
                      <CInputGroup className="mb-3">
                        <CFormInput
                          placeholder="Ulica"
                          invalid={!!errors.street}
                          feedbackInvalid={errors.street?.message}
                          {...methods.register("street")}
                        />
                      </CInputGroup>
                      <CInputGroup className="mb-3">
                        <CFormInput
                          placeholder="Numer budynku"
                          invalid={!!errors.building_number}
                          feedbackInvalid={errors.building_number?.message}
                          {...methods.register("building_number")}
                        />
                      </CInputGroup>
                      <CInputGroup className="mb-3">
                        <CFormInput
                          placeholder="Numer lokalu"
                          invalid={!!errors.apartment_number}
                          feedbackInvalid={errors.apartment_number?.message}
                          {...methods.register("apartment_number")}
                        />
                      </CInputGroup>
                      <CInputGroup className="mb-3">
                        <MaskedInput
                          mask="00-000"
                          placeholder="Kod pocztowy"
                          invalid={!!errors.postal_code}
                          feedbackInvalid={errors.postal_code?.message}
                          lazy={false}
                          {...methods.register("postal_code")}
                        />
                      </CInputGroup>
                      <CInputGroup className="mb-3">
                        <CFormInput
                          placeholder="Miasto"
                          invalid={!!errors.city}
                          feedbackInvalid={errors.city?.message}
                          {...methods.register("city")}
                        />
                      </CInputGroup>
                    </CCol>
                  )}
                </CRow>

                <div className="d-grid">
                  <button className="btn btn-primary" type="submit">
                    Dalej
                  </button>
                </div>
              </form>
            )}
          </CCardBody>
        </CCard>
      </FormProvider>
    </>
  );
};

export default Checkout;

import { CFormInput, CInputGroup } from "@coreui/react";
import { useForm } from "react-hook-form";
import {
  useAddDomainToCartMutation,
  useCheckDomainMutation,
} from "src/services/CartService";
import { useDebounce } from "@uidotdev/usehooks";
import { useEffect, useState } from "react";
import { Order } from "src/types/Order";
import { error } from "console";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { Subjectable } from "src/types/Subjectable";

interface CreateDomainProps {
  onSubmit?: any;
  onChange?: any;
  showSubmitButton?: boolean;
}
export interface CheckDomain {
  name: string;
}
const CreateDomain = ({
  onSubmit,
  onChange,
  showSubmitButton = true,
}: CreateDomainProps) => {
  const [checkDomain] = useCheckDomainMutation();
  const methods = useForm<CheckDomain>();
  const {
    register,
    watch,
    clearErrors,
    formState: { errors },
  } = methods;

  const [valid, setValid] = useState<string | null>();
  const value = watch("name");

  const debouncedValue = useDebounce(value, 1000);

  const handleCheckDomain = async (domain: string) => {
    try {
      const res = await checkDomain({
        name: domain,
      }).unwrap();
      setValid(res.success);
    } catch (e) {
      const error = e as FetchBaseQueryError;
      setValid(null);

      Object.entries<Record<any, any>>((error.data as any).errors).forEach(
        ([item, errors]) => {
          return methods.setError(item as any, {
            message: errors.join(","),
          });
        }
      );
    }
  };

  useEffect(() => {
    if (debouncedValue) {
      handleCheckDomain(debouncedValue);
      if (onChange) {
        onChange(debouncedValue);
      }
    }
  }, [debouncedValue]);

  useEffect(() => {
    setValid(null);
    clearErrors();
  }, [value]);

  return (
    <form onSubmit={methods.handleSubmit(onSubmit)}>
      <CInputGroup className="mb-3">
        <CFormInput
          {...register("name")}
          valid={!!valid}
          invalid={!!errors.name}
          feedbackValid={valid}
          feedbackInvalid={errors.name?.message}
        />
      </CInputGroup>
      {showSubmitButton && <button type="submit">Dodaj do zamówienia</button>}
    </form>
  );
};

export default CreateDomain;

import { CFormCheck, CFormInput, CFormLabel, CInputGroup } from "@coreui/react";
import { useFormContext } from "react-hook-form";
import { Template } from "src/types/Template";

interface FormProps {
  onSubmit: any;
}
const Form = ({ onSubmit }: FormProps) => {
  const methods = useFormContext<Template>();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = methods;
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <CFormLabel className="mt-3">Nazwa</CFormLabel>
      <CFormInput
        {...register("name")}
        invalid={!!errors.name}
        feedbackInvalid={errors.name?.message}
      />

      <CFormLabel className="mt-3">Katalog na serwerze</CFormLabel>
      <CFormInput
        {...register("path")}
        invalid={!!errors.path}
        feedbackInvalid={errors.path?.message}
      />

      <CFormLabel className="mt-3">Link do strony</CFormLabel>
      <CFormInput
        {...register("url")}
        invalid={!!errors.url}
        feedbackInvalid={errors.url?.message}
      />

      <CFormLabel className="mt-3">Screenshot</CFormLabel>
      <CFormInput
        {...register("screenshot")}
        invalid={!!errors.screenshot}
        feedbackInvalid={errors.screenshot?.message}
      />
    </form>
  );
};

export default Form;

import { CCard, CCardBody, CListGroup, CListGroupItem } from "@coreui/react";
import { useContext } from "react";
import { Link } from "react-router-dom";
import CartContext from "src/context/cart.context";
import { Order } from "src/types/Order";

interface SummaryProps {
  cart: Order;
}
const Summary = ({ cart }: SummaryProps) => {
  const { link } = useContext(CartContext);
  return (
    <>
      {link && (
        <CCard>
          <CCardBody>
            <h5>Podsumowanie</h5>
            <ul className="list-group">
              <li className="list-group-item">W sumie: {cart.price}</li>
              <li className="list-group-item">
                Po rabacie: {cart.price_with_discount}
              </li>
            </ul>
            <div className="d-grid gap-2">
              <Link to={link}>
                <button className="btn btn-primary mt-3">Dalej</button>
              </Link>
            </div>
          </CCardBody>
        </CCard>
      )}
    </>
  );
};

export default Summary;

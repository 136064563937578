import React from 'react'
import CIcon from '@coreui/icons-react'
import {
  cifAt,
  cilAsterisk,
  cilAt,
  cilBell,
  cilCalculator,
  cilCarAlt,
  cilCart,
  cilChartPie,
  cilCursor,
  cilDescription,
  cilDrop,
  cilExternalLink,
  cilGlobeAlt,
  cilNotes,
  cilPencil,
  cilPuzzle,
  cilSettings,
  cilSpeedometer,
  cilStar,
  cilTransfer,
} from '@coreui/icons'
import { CNavGroup, CNavItem, CNavTitle } from '@coreui/react'

export const userNav = [

  {
    component: CNavItem,
    name: 'Strony',
    to: '/websites',
    icon: <CIcon icon={cilGlobeAlt} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Konta email',
    to: '/emails',
    icon: <CIcon icon={cilAt} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Domeny',
    to: '/domains',
    icon: <CIcon icon={cilTransfer} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Zamówienia',
    to: '/orders',
    icon: <CIcon icon={cilCart} customClassName="nav-icon" />,
  },

  {
    component: CNavTitle,
    name: 'Konto',
  },
  {
    component: CNavItem,
    name: 'Ustawienia konta',
    to: '/settings',
    icon: <CIcon icon={cilSettings} customClassName="nav-icon" />,
  }
]

export const adminNav = [
  {
    component: CNavTitle,
    name: 'System',
  },

  {
    component: CNavItem,
    name: 'Pakiety',
    to: '/plans',
    icon: <CIcon icon={cilSettings} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Generator linków',
    to: '/plans/generator',
    icon: <CIcon icon={cilSettings} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Usługi',
    to: '/services',
    icon: <CIcon icon={cilSettings} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Szablony',
    to: '/templates',
    icon: <CIcon icon={cilSettings} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Cenniki domen',
    to: '/domain-prices',
    icon: <CIcon icon={cilSettings} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Logowanie do API OVH',
    to: '/ovh',
    icon: <CIcon icon={cilSettings} customClassName="nav-icon" />,
  }

]


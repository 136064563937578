import React, { useEffect, useRef, useState } from "react";

interface CardFormProps {
  token: string;
  signature: string;
  onSuccess: any;
  onFail: any;
}

const CardForm = ({ token, signature, onSuccess, onFail }: CardFormProps) => {
  const dictionary = {
    cardHolderLabel: "Imię i nazwisko",
    cardNumberLabel: "Numer karty",
    cvvLabel: "Kod CVV",
    expDateLabel: "Data wygaśnięcia karty",
    payButtonCaption: "Zapłać",
    threeDSAuthMessage: "Weryfikacja",
  };

  useEffect(() => {
    document.addEventListener("paid", function () {
      onSuccess(true);
    });

    document.addEventListener("payment_failed", function (e) {
      onFail(e);
    });
  }, []);
  useEffect(() => {
    const script = document.createElement("script");

    script.src = `https://sandbox.przelewy24.pl/inchtml/ajaxPayment/ajax.js?token=${token}`;
    script.addEventListener("load", function () {
      dispatchEvent(new Event("load"));
    });

    const localScript = document.createElement("script");
    localScript.innerHTML = `function success (id) {
        console.log(id);
      document.dispatchEvent(new Event('paid'))
    }; 
    
    function fail (error) {
        console.log(error);
      document.dispatchEvent(new Event('payment_failed'))
    
    };`;
    document.body.appendChild(localScript);
    document.head.appendChild(script);
  }, [token, signature]);

  return (
    <div className="CardForm" data-testid="CardForm">
      <div className="first-side">
        <span className="chip" />
        <span className="visa" />
      </div>
      <div className="second-side">
        <div className="ccv-bar" />
      </div>

      <div
        id="P24FormContainer"
        data-dictionary={JSON.stringify(dictionary)}
        data-sign={signature}
        data-successcallback="success"
        data-failurecallback="fail"
      ></div>
    </div>
  );
};

CardForm.propTypes = {};

CardForm.defaultProps = {};

export default CardForm;

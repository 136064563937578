import {
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import { skipToken } from "@reduxjs/toolkit/query";
import { subscribe } from "diagnostics_channel";
import { FormProvider, useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useGetOrderQuery } from "src/services/OrderService";
import {
  useGetPlanQuery,
  useUpdatePlanMutation,
} from "src/services/PlanService";
import { OrderItem } from "src/types/OrderItem";
import { Plan } from "src/types/Plan";
import { Website } from "src/types/Website";
import Form from "./Form";
import { useEffect } from "react";
import { ApiErrorSetter } from "src/helpers/ApiErrorSetter";

const Update = () => {
  const { id } = useParams();
  const { currentData: planResponse } = useGetPlanQuery(id ?? skipToken);
  const plan = planResponse?.data;
  const methods = useForm<Plan>();
  const { reset, watch, setError, clearErrors } = methods;

  const [updatePlan] = useUpdatePlanMutation();

  const navigate = useNavigate();
  const handleOnClose = () => {
    navigate("/plans");
  };
  useEffect(() => {
    reset(plan);
  }, [plan]);

  const form = watch();

  const handleOnSubmit = async () => {
    try {
      clearErrors();
      const res = await updatePlan(form).unwrap();
      handleOnClose();
    } catch (e) {
      ApiErrorSetter(e, setError);
    }
  };
  return (
    <CModal visible={true} size="xl" onClose={handleOnClose}>
      {plan && (
        <>
          <CModalHeader>
            <h5>Pakiet {plan.name}</h5>
          </CModalHeader>
          <CModalBody>
            <FormProvider {...methods}>
              <Form onSubmit={handleOnSubmit} />
            </FormProvider>
          </CModalBody>
          <CModalFooter className="mt-5">
            <button onClick={handleOnSubmit} className="btn btn-primary">
              Zapis
            </button>
            <button
              onClick={handleOnClose}
              className="btn btn-danger text-white"
            >
              Zamknij
            </button>
          </CModalFooter>
        </>
      )}
    </CModal>
  );
};

export default Update;

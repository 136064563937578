import { createSlice } from "@reduxjs/toolkit";

export const SidebarReducer = createSlice({
  name: "showSidebar",
  initialState: null,
  reducers: {
    set: (state, action) => action.payload,
  },
});

export const { set } = SidebarReducer.actions;

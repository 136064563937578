import { CFormInput, CInputGroup } from "@coreui/react";
import { useForm } from "react-hook-form";
import {
  useAddDomainToCartMutation,
  useCheckDomainMutation,
} from "src/services/CartService";
import { useDebounce } from "@uidotdev/usehooks";
import { useEffect, useState } from "react";
import { Order } from "src/types/Order";
import { error } from "console";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { Subjectable } from "src/types/Subjectable";

interface CreateSubdomainProps {
  onSubmit?: any;
}
interface CheckDomain {
  name: string;
}
const CreateSubdomain = ({ onSubmit }: CreateSubdomainProps) => {
  const [checkDomain] = useCheckDomainMutation();

  const methods = useForm<CheckDomain>();
  const {
    register,
    watch,
    clearErrors,
    formState: { errors },
  } = methods;
  const [valid, setValid] = useState<string | null>();
  const [addDomainToCart] = useAddDomainToCartMutation();
  const value = watch("name");

  const debouncedValue = useDebounce(value, 1000);

  const handleCheckDomain = async (domain: string) => {
    try {
      const res = await checkDomain({
        name: domain,
      }).unwrap();
      setValid(res.success);
    } catch (e) {
      const error = e as FetchBaseQueryError;
      setValid(null);

      Object.entries<Record<any, any>>((error.data as any).errors).forEach(
        ([item, errors]) => {
          return methods.setError(item as any, {
            message: errors.join(","),
          });
        }
      );
    }
  };

  const handleOnSubmit = async (form: CheckDomain) => {
    await onSubmit({
      name: `${debouncedValue}.${process.env.REACT_APP_REGISTER_DOMAIN}`,
    });
  };

  useEffect(() => {
    if (debouncedValue) {
      handleCheckDomain(
        `${debouncedValue}.${process.env.REACT_APP_REGISTER_DOMAIN}`
      );
    }
  }, [debouncedValue]);

  useEffect(() => {
    setValid(null);
    clearErrors();
  }, [value]);

  return (
    <form onSubmit={methods.handleSubmit(handleOnSubmit)}>
      <CInputGroup className="mb-3">
        <CFormInput
          {...register("name")}
          valid={!!valid}
          invalid={!!errors.name}
        />
        <span className="input-group-text" id="basic-addon2">
          .{process.env.REACT_APP_REGISTER_DOMAIN}
        </span>
        <div className="valid-feedback">{valid}</div>
        <div className="invalid-feedback">{errors.name?.message}</div>
      </CInputGroup>
      <button type="submit">Dodaj do zamówienia</button>
    </form>
  );
};

export default CreateSubdomain;

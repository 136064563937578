import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";

import { useLogoutMutation } from "src/services/AuthService";

export const Logout = () => {
  const [logoutMutation] = useLogoutMutation();
  const navigate = useNavigate();

  useEffect(() => {
    logoutMutation(null)
      .unwrap()
      .then(() => {
        navigate("/login");
      });
  }, []);

  return <></>;
};

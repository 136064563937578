import {
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import { skipToken } from "@reduxjs/toolkit/query";
import { subscribe } from "diagnostics_channel";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useGetOrderQuery } from "src/services/OrderService";
import { OrderItem } from "src/types/OrderItem";
import { Website } from "src/types/Website";

const Show = () => {
  const { id } = useParams();
  const { currentData: orderResponse } = useGetOrderQuery(id ?? skipToken);
  const order = orderResponse?.data;
  const getSubjectableName = (item: OrderItem) => {
    switch (item.subjectable_type) {
      case "App\\Models\\Website":
        return "Strona " + (item.subjectable as Website).domain?.name;
      case "App\\Models\\WebsiteConfig":
        return "Nowa strona";
      default:
        return <>-</>;
    }
  };
  const navigate = useNavigate();
  const handleOnClose = () => {
    navigate("/orders");
  };
  return (
    <CModal visible={true} size="xl" onClose={handleOnClose}>
      {order && (
        <>
          <CModalHeader>
            <h5>Zamówienie {order?.number}</h5>
          </CModalHeader>
          <CModalBody>
            <CTable>
              <CTableHead>
                <CTableHeaderCell>lp.</CTableHeaderCell>
                <CTableHeaderCell>Pozycja</CTableHeaderCell>
                <CTableHeaderCell>Dotyczy</CTableHeaderCell>
                <CTableHeaderCell>Cena</CTableHeaderCell>
                <CTableHeaderCell>Cena po rabacie</CTableHeaderCell>
              </CTableHead>
              <CTableBody>
                {order?.items.map((item, index) => (
                  <CTableRow key={item.id}>
                    <CTableDataCell>{index + 1}</CTableDataCell>
                    <CTableDataCell>{item.name}</CTableDataCell>
                    <CTableDataCell>{getSubjectableName(item)}</CTableDataCell>
                    <CTableDataCell>{item.price}</CTableDataCell>
                    <CTableDataCell>{item.price_with_discount}</CTableDataCell>
                  </CTableRow>
                ))}
                <CTableRow>
                  <CTableDataCell colSpan={3}></CTableDataCell>
                  <CTableDataCell>
                    <b>W sumie</b>
                  </CTableDataCell>
                  <CTableDataCell>{order?.price}</CTableDataCell>
                </CTableRow>
                <CTableRow>
                  <CTableDataCell colSpan={3}></CTableDataCell>
                  <CTableDataCell>
                    <b>Po rabacie</b>
                  </CTableDataCell>

                  <CTableDataCell>{order?.price_with_discount}</CTableDataCell>
                </CTableRow>
              </CTableBody>
            </CTable>
          </CModalBody>
          <CModalFooter className="mt-5">
            <button onClick={handleOnClose} className="btn btn-primary">
              Zamknij
            </button>
          </CModalFooter>
        </>
      )}
    </CModal>
  );
};

export default Show;

import { Template } from "src/types/Template";
import { ListResponse, SingleItemResponse } from "../types/api/Response";
import Api from "./Api";
import { ListParams } from "src/types/api/ListParams";
import { Plan } from "src/types/Plan";

export const TemplateService = Api.injectEndpoints({
  endpoints: (build) => ({
    templates: build.query<ListResponse<Template>, ListParams>({
      query: (params) => ({
        url: `/templates`,
        params: params,
      }),
      providesTags: ["TEMPLATES"],
    }),

    getTemplate: build.query<SingleItemResponse<Template>, string>({
      query: (templateId) => ({
        url: `templates/${templateId}`,
      }),
      providesTags: (result: any, error: any, arg: any) => [
        { type: "TEMPLATES", params: arg },
        "TEMPLATES",
      ],
    }),
    updateTemplate: build.mutation<SingleItemResponse<Template>, Template>({
      query: (template: Template) => ({
        url: `/templates/${template.id}`,
        body: template,
        method: "PATCH",
      }),

      invalidatesTags: ["TEMPLATES"],
    }),

    deleteTemplate: build.mutation<null, string>({
      query: (template: string) => ({
        url: `/templates/${template}`,

        method: "DELETE",
      }),

      invalidatesTags: ["TEMPLATES"],
    }),
    createTemplate: build.mutation<SingleItemResponse<Template>, Template>({
      query: (template: Template) => ({
        url: `/templates`,
        body: template,
        method: "POST",
      }),

      invalidatesTags: ["TEMPLATES"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useTemplatesQuery,
  useLazyTemplatesQuery,
  useGetTemplateQuery,
  useLazyGetTemplateQuery,
  useCreateTemplateMutation,
  useDeleteTemplateMutation,
  useUpdateTemplateMutation,
} = TemplateService;

import { skipToken } from "@reduxjs/toolkit/query";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import {
  useCreateCartMutation,
  useGetCartQuery,
} from "src/services/CartService";
import { setCartId } from "src/store/reducers/cart";
import Subjectable from "./Subjectable";
import {
  CCard,
  CCardBody,
  CCardTitle,
  CCol,
  CContainer,
  CRow,
} from "@coreui/react";
import AddDomain from "./AddDomain";
import Summary from "./Summary";
import CartContext from "src/context/cart.context";

export const Cart = () => {
  const dispatch = useDispatch();
  const cartId = useSelector<any>((store) => store.cart) as string;
  const [createCartMutation] = useCreateCartMutation();

  const {
    currentData: cartResponse,
    isFetching,
    isError,
  } = useGetCartQuery(cartId ? cartId : skipToken);

  const cart = cartResponse?.data;

  const [link, setLink] = useState<string>();

  const createCart = async () => {
    try {
      const res = await createCartMutation(null).unwrap();
      dispatch(setCartId(res.data.id));
    } catch (e) {}
  };

  if (isError) {
    dispatch(setCartId(null));
  }

  useEffect(() => {
    if (!cartId) {
      if (!isFetching) {
        if (!cart) {
          createCart();
        }
      }
    }
  }, [cartId, isFetching, cart]);

  return (
    <CContainer>
      <CartContext.Provider value={{ link: link, setLink: setLink }}>
        <CRow>
          <CCol md={link ? 9 : 12} className="mt-4">
            <CCard>
              <CCardBody>
                <h5 className="card-ttle">Koszyk</h5>
                {cart && <Subjectable cart={cart} />}
              </CCardBody>
            </CCard>

            <Outlet />
          </CCol>
          {link && cart && (
            <CCol md={3} className="mt-4">
              <Summary cart={cart} />
            </CCol>
          )}
        </CRow>
      </CartContext.Provider>
    </CContainer>
  );
};

import {
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import { skipToken } from "@reduxjs/toolkit/query";
import { subscribe } from "diagnostics_channel";
import { FormProvider, useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useGetOrderQuery } from "src/services/OrderService";
import {
  useCreatePlanMutation,
  useGetPlanQuery,
  useUpdatePlanMutation,
} from "src/services/PlanService";
import { OrderItem } from "src/types/OrderItem";
import { Plan } from "src/types/Plan";
import { Website } from "src/types/Website";
import Form from "./Form";
import { useEffect } from "react";
import { ApiErrorSetter } from "src/helpers/ApiErrorSetter";
import { Service } from "src/types/Service";
import { useCreateServiceMutation } from "src/services/ServiceService";
import { DomainPrice } from "src/types/DomainPrice";
import {
  useCreateDomainPriceMutation,
  useDomainPricesQuery,
} from "src/services/DomainPriceService";

const Create = () => {
  const methods = useForm<DomainPrice>();
  const { watch, setError, clearErrors } = methods;

  const [createDomainPrice] = useCreateDomainPriceMutation();

  const navigate = useNavigate();
  const handleOnClose = () => {
    navigate("/domain-prices");
  };

  const form = watch();

  const handleOnSubmit = async () => {
    try {
      clearErrors();
      const res = await createDomainPrice(form).unwrap();
      handleOnClose();
    } catch (e) {
      ApiErrorSetter(e, setError);
    }
  };

  return (
    <CModal visible={true} size="xl" onClose={handleOnClose}>
      <>
        <CModalHeader>
          <h5>Dodaj nowy cennik domen</h5>
        </CModalHeader>
        <CModalBody>
          <FormProvider {...methods}>
            <Form onSubmit={handleOnSubmit} />
          </FormProvider>
        </CModalBody>
        <CModalFooter className="mt-5">
          <button onClick={handleOnSubmit} className="btn btn-primary">
            Zapis
          </button>
          <button onClick={handleOnClose} className="btn btn-danger text-white">
            Zamknij
          </button>
        </CModalFooter>
      </>
    </CModal>
  );
};

export default Create;
